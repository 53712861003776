@import "colors";
@import "borders";
@import "typography";
@import "spacing";
@import "../mixins";

//Styles of <button> and <a>

%button-text {
    padding: 0 18px !important;
    min-width: 67px;
    min-height: 32px;
    @include typography-button;
    @include border-radius-16;
}

a, button, label {
    &.button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        text-align: center;

        &__primary {
            background-color: $mf-color-blue-moment-100;
            color: $mf-color-white;
            @extend %button-text;

            &:hover {
                background-color: $mf-color-deep-blue-80;
                color: $mf-color-white;
            }

            &:focus {
                background-color: $mf-color-blue-moment-100;
                outline: 1px solid $mf-color-white;
                outline-offset: -3px;
                @include border-radius-16;
            }

            &:focus:not(:focus-visible) {
                outline: none;
            }

            &:active {
                background-color: $mf-color-deep-blue-100;
                @include border-radius-16;
            }

            &:disabled {
                background-color: $mf-color-night-sky-20;
                color: $mf-color-white;
            }
        }

        &__secondary {
            background-color: $mf-color-white;
            color: $mf-color-night-sky-100;
            border: 1px solid $mf-color-night-sky-40;
            @extend %button-text;

            &:focus {
                background-color: $mf-color-white;
                outline: 2px solid $mf-color-blue-moment-100;
                border-color: transparent;
                @include border-radius-16;
            }

            &:focus:not(:focus-visible) {
                outline: none;
                border-color: $mf-color-night-sky-40;
            }

            &:hover {
                background-color: $mf-color-night-sky-60;
                color: $mf-color-white;
                border: 1px solid transparent;
            }

            &:active {
                background-color: $mf-color-night-sky-80;
                color: $mf-color-white;
                border: 1px solid transparent;
                @include border-radius-16;
            }

            &:disabled {
                background-color: $mf-color-night-sky-20;
                color: $mf-color-white;
                border: 1px solid transparent;
            }
        }

        &__tertiary {
            color: $mf-color-blue-moment-100;
            @extend %button-text;

            &:focus {
                outline: 2px solid $mf-color-blue-moment-100;
                border-color: transparent;
                color: $mf-color-blue-moment-100;
                @include border-radius-16;
            }

            &:focus:not(:focus-visible) {
                outline: none;
            }

            &:hover {
                background-color: $mf-color-night-sky-10;
                color: $mf-color-blue-moment-100;
            }

            &:active {
                background-color: $mf-color-night-sky-80;
                color: $mf-color-white;
                @include border-radius-16;
            }

            &:disabled {
                color: $mf-color-night-sky-20;
            }
        }

        &__error {
            color: $mf-color-alert-red;
            border: 1px solid $mf-color-alert-red;
            @extend %button-text;

            &:focus {
                outline: 2px solid $mf-color-alert-red;
                border-color: $mf-color-alert-red;
                color: $mf-color-alert-red;
                @include border-radius-16;
            }

            &:focus:not(:focus-visible) {
                outline: none;
                border-color: $mf-color-alert-red;
            }

            &:hover {
                background-color: $mf-color-alert-red;
                color: $mf-color-white;
                border: 1px solid transparent;
            }

            &:active {
                background-color: darken($mf-color-alert-red, 10%);
                color: $mf-color-white;
                border: 1px solid transparent;
                @include border-radius-16;
            }

            &:disabled {
                color: $mf-color-night-sky-20;
                border: 1px solid transparent;
            }
        }

        &__icon {
            border-radius: 50%;

            &:hover {
                background-color: $mf-color-deep-blue-10;
            }

            &:active {
                background-color: $mf-color-deep-blue-20;
                @include transition;
            }

            &.disabled {
                color: $mf-color-night-sky-20;
            }
        }
    }
}


a, a:not([href]):not([class]), button.button__link{
    cursor: pointer;
    text-decoration: none;
    color: $mf-color-blue-moment-100;

    &:focus {
        outline: 2px solid $mf-color-blue-moment-100;
        outline-offset: 2px;
        color: $mf-color-blue-moment-100;
        @include mf-border-radius(2px);
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }

    &:hover {
        color: $mf-color-deep-blue-80;
    }

    &:active {
        color: $mf-color-deep-blue-100;
    }

    &:disabled {
        color: $mf-color-night-sky-20;
    }
}
// Use class generated here when hover and focus states are needed on button__icon
// i.e. for a 24px icon: <button class="button button__icon button__icon-24"><nk-icon.../></button>
@for $i from 16 through 48 {
    @if $i % 4 == 0 {
        .button__icon-#{$i} {
            width: calc(#{$i}px + 4px);
            height: calc(#{$i}px + 4px);

            .icon {
                width: #{$i}px;
                height: #{$i}px;
            }
        }
    }
}
